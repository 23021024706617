<template>
  <base-layout-two page-title="Edit Engagement" page-default-back-link="/engagements">

    <section class="padding-8">
      <v-form @submit="updateEngagement">
        <base-card title="Engagement Details">
          <ion-grid>
            <ion-row>
              <!-- Status -->
              <ion-col size="12">
                <base-input label-text="Status *">
                  <v-field name="status" v-slot="{ field }" v-model="engagement.status" :rules="required">
                    <select v-bind="field" class="regular-select">
                      <option value="coordinating">Coordinating</option>
                      <option value="pending">Pending</option>
                      <option value="in_progress">In Progress</option>
                      <option value="complete">Complete</option>
                    </select>
                  </v-field>
                </base-input>
                <v-error-message name="status" class="error-message"></v-error-message>
              </ion-col>

              <!-- Address -->
              <ion-col size="12" size-lg="6">
                <base-input label-text="Address *">
                  <vue-google-autocomplete id="map" classname="places-input" placeholder="Client Address"
                    ref="autocomplete" v-on:placechanged="setLocation">
                  </vue-google-autocomplete>
                </base-input>
                <v-error-message name="user_id" class="error-message">
                </v-error-message>
              </ion-col>

              <!-- Unit Number -->
              <ion-col size="12" size-lg="6">
                <base-input label-text="Unit Number">
                  <v-field name="unit_number" v-slot="{ field }" v-model="engagement.unit_number">
                    <ion-input name="unit_number" v-bind="field"></ion-input>
                  </v-field>
                </base-input>
                <v-error-message name="unit_number" class="error-message">
                </v-error-message>
              </ion-col>

              <!-- Day Sessions -->
              <ion-col size="12" size-lg="6">
                <base-input label-text="Day Sessions *">
                  <v-field name="sessions_day" v-slot="{ field }" v-model="engagement.sessions_day" :rules="required">
                    <ion-input name="sessions_day" v-bind="field" type="number"></ion-input>
                  </v-field>
                </base-input>
                <v-error-message name="sessions_day" class="error-message">
                </v-error-message>
              </ion-col>

              <!-- Night Sessions -->
              <ion-col size="12" size-lg="6">
                <base-input label-text="Night Sessions *">
                  <v-field name="sessions_night" v-slot="{ field }" v-model="engagement.sessions_night" :rules="required">
                    <ion-input name="sessions_night" v-bind="field" type="number"></ion-input>
                  </v-field>
                </base-input>
                <v-error-message name="sessions_night" class="error-message">
                </v-error-message>
              </ion-col>

              <!-- Subtotal -->
              <ion-col size="12" size-lg="6">
                <base-input label-text="Subtotal *">
                  <v-field name="price_retail" v-slot="{ field }" v-model="engagement.price_final" :rules="required">
                    <ion-input name="price_retail" v-bind="field" type="number"></ion-input>
                  </v-field>
                </base-input>
                <v-error-message name="price_retail" class="error-message">
                </v-error-message>
              </ion-col>

              <!-- Taxes -->
              <ion-col size="12" size-lg="6">
                <base-input label-text="Taxes & Fees *">
                  <v-field name="price_taxes" v-slot="{ field }" v-model="engagement.price_taxes" :rules="required">
                    <ion-input name="price_taxes" v-bind="field" type="number"></ion-input>
                  </v-field>
                </base-input>
                <v-error-message name="price_taxes" class="error-message">
                </v-error-message>
              </ion-col>

              <!-- Total -->
              <ion-col size="12" size-lg="6">
                <base-input label-text="Total *">
                  <v-field name="price_final" v-slot="{ field }" v-model="engagement.price_total" :rules="required">
                    <ion-input name="price_final" v-bind="field" type="number" :rules="required"></ion-input>
                  </v-field>
                </base-input>
                <v-error-message name="price_final" class="error-message">
                </v-error-message>
              </ion-col>

              <!-- General Notes -->
              <ion-col size="12">
                <base-input label-text="General Notes">
                  <v-field name="general_notes" v-slot="{ field }" v-model="engagement.general_notes">
                    <ion-textarea :auto-grow="true" rows="4" v-bind="field" placeholder="General Notes" autocapitalize>
                    </ion-textarea>
                  </v-field>
                </base-input>
                <v-error-message name="general_notes" class="error-message">
                </v-error-message>
              </ion-col>
            </ion-row>
          </ion-grid>
        </base-card>

        <ion-button type="submit" expand="block" class="margin-x-8 margin-y-16">Save</ion-button>
      </v-form>
    </section>
  </base-layout-two>
</template>

<script>
import {
  IonGrid,
  IonRow,
  IonCol,
  IonInput,
  IonTextarea,
  IonButton,
} from "@ionic/vue";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import { Field, Form, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import { mapActions } from "vuex";

export default {
  components: {
    IonGrid,
    IonRow,
    IonCol,
    IonInput,
    IonTextarea,
    IonButton,
    VueGoogleAutocomplete,
    VForm: Form,
    VField: Field,
    VErrorMessage: ErrorMessage,
  },

  data() {
    return {
      engagement: {},
      required: yup.string().nullable().required(),
    };
  },

  computed: {
    address() {
      return `${this.engagement.street_number} ${this.engagement.route}, ${(this.engagement.unit_number) ? `${this.engagement.unit_number}, ` : ''} ${this.engagement.locality}, ${this.engagement.administrative_area_level_1} ${this.engagement.postal_code}`
    }
  },

  watch: {
  },

  async ionViewWillEnter() {
    await this.fetchEngagement();
  },

  methods: {
    ...mapActions(["loadToast", "setShowLoading"]),

    /**
     * Fetch Engagement
     */
    async fetchEngagement() {
      await this.axios
        .get(`${process.env.VUE_APP_API}/engagements/${this.$route.params.id}`)
        .then((response) => {
          this.engagement = response.data.engagement;

          // Put an initial value for Address Search
          if (this.engagement.street_number) {
            let address = `${this.engagement.street_number} ${this.engagement.route}, ${this.engagement.locality}, ${this.engagement.administrative_area_level_1} ${this.engagement.postal_code}`
            this.$refs.autocomplete.update(address)
          }
        })
        .catch((error) => { console.log(error); });
    },

    /**
     * Set Location
     */
    setLocation(placeData) {
      console.log(placeData);
      this.engagement.street_number = placeData.street_number
      this.engagement.route = placeData.route
      this.engagement.locality = placeData.locality
      this.engagement.administrative_area_level_1 = placeData.administrative_area_level_1
      this.engagement.administrative_area_level_2 = placeData.administrative_area_level_2
      this.engagement.postal_code = placeData.postal_code
      this.engagement.country = placeData.country
    },

    /**
     * Update the Engagement
     */
    async updateEngagement() {
      const config = {
        method: "patch",
        url: `${process.env.VUE_APP_API}/engagements/${this.engagement.id}`,
        data: this.engagement,
      };

      // Show a network loading indicator
      this.setShowLoading(true);

      await this.axios(config)
        .then((response) => {
          this.engagement = response.data.engagement;
          // Turn off the network loading indicator and prompt user with a toast
          this.setShowLoading(false);
          this.loadToast({
            message: "Engagement Updated",
            color: "secondary",
          });

          this.$router.replace(`/engagements/${this.engagement.id}`);
        })
        .catch((error) => {
          console.log(error);

          // Turn off the network loading indicator and prompt user with a toast
          this.setShowLoading(false);
          this.loadToast({ message: "Something went wrong", color: "danger" });
        });
    },
  },
};
</script>

<style scoped>
.places-input {
  width: 100%;
  background-color: #fff;
  border: 0px;
  border-radius: 4px;
  padding-top: 8px;
  padding-bottom: 8px;
  font-size: 18px
}
</style>